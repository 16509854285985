import './App.css';

function App() {
    const handleLogoClick = () => {
        window.open('https://play.google.com/store/apps/details?id=com.thecornfiles.martymane.theateball&hl=en_US&gl=US&pli=1', '_blank');
    }

  return (
    <div className="App">
        <h1>The Ate Ball</h1>
        <img id={"ateBallIcon"} src={require('./images/AteBall.png')} alt={"the ate ball"} width={"400px"} height={"320px"} onClick={handleLogoClick}/>
        <h2>Privacy Policy</h2>
        <p>The Ate Ball application does not collect ANY user data or information in ANY form.</p>
        <p>Anything you do within The Ate Ball application (click image above for reference) is completely private and will never be tracked or retained in any capacity.</p>
        <p>This application is merely a passion project of mine and I have no intention of ever monetizing the application through the collection and sale of user data.</p>
    </div>
  );
}

export default App;
